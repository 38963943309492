import { ENV_DEV, ENV_STAGING, ENV_PRODUCTION } from '../../utils/env'

export default (env) => {
  switch (env) {
    case ENV_DEV:
      return 'http://localhost:15700'
    case ENV_STAGING:
      return 'https://staging-api.purchease.com'
    case ENV_PRODUCTION:
      return 'https://api.purchease.com'
    default:
      // TODO: airbrake
  }
}
