import { ENV_DEV, ENV_STAGING, ENV_PRODUCTION } from '../../utils/env'

export default (env) => {
  switch (env) {
    case ENV_DEV:
      return 'http://localhost:3000'
    case ENV_STAGING:
      return 'https://staging-widget.fidmarques.com'
    case ENV_PRODUCTION:
      return 'https://widget.fidmarques.com'
    default:
      // TODO: airbrake
  }
}
